<template>
  <el-container class="container">
    <el-header class="header-wrapper">
      <div class="logo-box">
        <img src="../../assets/logo_mini.png" width="36" />
        <span class="logo-title">313FM - {{ $t('forgot.loginTitle') }}</span>
        <span class="logo-name">{{ $t('forgot.forgotPass') }}</span>
      </div>
      <div class="info-wrapper">
        <span><i class="el-icon-online-customer-service"></i>{{ $t('forgot.contactPhone') }}</span>
        <a @click="getOfficialWebsite"><i class="el-icon-home"></i>{{ $t('forgot.home') }}</a>
        <a @click="jumpLogin"><i class="el-icon-login"></i>{{ $t('forgot.login') }}</a>
        <el-dropdown class="language-box" @command="switchLanguage">
          <span> {{ showLanguage }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :key="langIndex" :command="langItem.value" v-for="(langItem, langIndex) of languageItemArr">{{ langItem.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-main>
      <div class="content-wrapper">
        <div class="steps-box">
          <el-steps :active="stepNum" :align-center="true" :class="[stepNum == 3 ? 'success' : '']">
            <el-step :title="$t('forgot.verAcc')"></el-step>
            <el-step :title="$t('forgot.modPass')"></el-step>
            <el-step :title="$t('forgot.resComp')" :icon="stepNum == 3 ? 'el-icon-check' : ''"></el-step>
          </el-steps>
        </div>

        <!-- 第一步 -->
        <div v-show="stepNum == 1" class="pwd-wrapper" style="width: 75%">
          <el-form label-width="200px" :model="oneStepSubmitData" :rules="oneStepSubmitRules" ref="oneStepForm">
            <el-form-item :label="$t('forgotAccount.peShortName')" prop="name">
              <el-input :placeholder="$t('forgotAccount.peShortNameTips')" v-model="oneStepSubmitData.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('forgotAccount.accountId')" prop="user">
              <el-input :placeholder="$t('forgotAccount.accountIdTips')" v-model="oneStepSubmitData.user"></el-input>
            </el-form-item>
            <el-form-item :label="$t('forgotAccount.verCode')" prop="img_code">
              <el-col :span="17">
                <el-input :placeholder="$t('forgotAccount.verCodeTips')" v-model="oneStepSubmitData.img_code" maxlength="4"></el-input>
              </el-col>
              <el-col :span="6" :offset="1">
                <img :src="imgsrc" @click="getImageCode" style="display: block; border: 1px solid #ccc; cursor: pointer" />
              </el-col>
            </el-form-item>
            <el-form-item label="">
              <el-button :disabled="isNext" type="primary" @click="pwdFirstAccount" style="width: 100%">{{ $t('forgot.nextStep') }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 第二步 -->
        <div v-show="stepNum == 2" class="pwd-wrapper" style="width: 80%">
          <el-form label-width="220px" :model="twoStepSubmitData" :rules="twoStepSubmitRules" ref="twoStepForm">
            <el-form-item :label="$t('forgotAccount.peAdminPhone')">
              <template v-if="PEAuthPhoneMsgList.length == 0">
                {{ phoneMsg }}
              </template>
              <template v-if="PEAuthPhoneMsgList.length != 0">
                <el-select v-model="twoStepSubmitData.phoneName" style="width: 100%">
                  <el-option v-for="option in PEAuthPhoneMsgList" :key="option.value" :label="option.authphone" :value="option.value"> </el-option>
                </el-select>
              </template>
            </el-form-item>
            <el-form-item :label="$t('forgotAccount.peAdminPhone')" prop="userphone">
              <el-input :placeholder="$t('forgotAccount.peAdminPhoneTips')" v-model="twoStepSubmitData.userphone"></el-input>
            </el-form-item>
            <el-form-item :label="$t('forgotAccount.verCode')" prop="img_code2">
              <el-col :span="17">
                <el-input :placeholder="$t('forgotAccount.verCodeTips')" v-model="twoStepSubmitData.img_code2" maxlength="4"></el-input>
              </el-col>
              <el-col :span="6" :offset="1">
                <img :src="imgsrc2" @click="getImageCode2" style="display: block; border: 1px solid #ccc; cursor: pointer" />
              </el-col>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="pwdgetAccount" style="width: 100%">{{ $t('forgot.nextStep') }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 第三步 -->
        <div v-show="stepNum == 3" class="pwd-wrapper">
          <div class="finish-title">{{ $t('forgotAccount.accmodSuccessMsg') }}{{ twoStepSubmitData.phoneName }}</div>
          <div class="finish-content">
            <i18n path="forgot.modSuccessMsgJumpMsg" :tag="false">
              <!--              <template slot="seconds">-->
              <!--                {{ time }}-->
              <!--              </template>-->
              <template slot="loginPage">
                <el-button @click="jumpLogin" type="text">{{ $t('forgot.loginPage') }}</el-button>
              </template>
            </i18n>
          </div>
          <el-divider>{{ $t('tutorialVideo.or') }}</el-divider>
          <div class="tutorial_wrapper">
            <div class="title">{{ $t('tutorialVideo.viewTutorial') }}</div>
            <div class="item_box">
              <a :href="vItem.link" target="_blank" v-for="vItem in tutorialVideos" :key="vItem.link">{{
                  vItem.label
                }}</a>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="footer-wrapper"  v-if="$store.getters.lang !== 'tw'">
      {{ $t('common.copyrightDesc') }}
    </el-footer>
  </el-container>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
